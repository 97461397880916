.clickable {
  user-select: none;
  cursor: pointer;
}
span {
  font-size: 17px;
  font-weight: 400;
  color: var(--onSurfaceStrong);
  &.medium {
    font-weight: 500;
  }
  &.bold {
    font-weight: 600;
  }
  &.error {
    color: var(--error);
  }
  &.warning {
    color: var(--warning);
  }
  &.success {
    color: var(--success);
  }
  &.small {
    font-size: 14px;
  }
  &.big {
    font-size: 19px;
  }
  &.large {
    font-size: 22px;
  }
  &.extraLarge {
    font-size: 28px;
  }
}
.pageHeader {
  background-color: var(--surface);
  height: 220px;
  border-bottom: 1px solid var(--border);
  padding: 32px 48px 0 48px;
  display: flex;
  flex-direction: row;
  .pageTitle {
    font-size: 28px;
    display: block;
    user-select: none;
  }
  .pageDescription {
    font-size: 18px;
    color: var(--onSurfaceLight);
    user-select: none;
  }
  .pageHeaderLeft {
    flex-grow: 1;
  }
  .pageHeaderRight {
    margin-top: 24px;
    &.noMarginTop {
      margin-top: 0;
    }
  }
}
.pageContent {
  padding: 0 48px;
  transform: translateY(-80px);
  display: flex;
  .card {
    background-color: var(--surface);
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border: 1px solid var(--border);
    max-height: calc(100vh - 184px);
    flex-grow: 1;
    position: relative;
    &.leftList {
      max-width: 440px;
      min-width: 440px;
      margin-right: 32px;
      .searchHeader {
        height: 72px;
        border-bottom: 1px solid var(--border);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 24px;
        i {
          margin-right: 16px;
          margin-bottom: 2px;
        }
        input {
          height: 48px;
          border: none;
          outline: none;
          flex-grow: 1;
          font-size: 17px;
          &::placeholder {
            color: var(--onSurfaceLight);
          }
        }
      }
      & > .listContent {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100% - 64px);
        &.hasSearchHeader {
          max-height: calc(100% - 72px);
        }
        .defaultListElement {
          display: flex;
          flex-direction: row;
          padding: 16px 24px;
          border-bottom: 1px solid var(--border);
          cursor: pointer;
          user-select: none;
          &:hover {
            background-color: var(--surfaceLayer);
          }
          .elemImageContainer {
            margin-right: 24px;
            height: 88px;
            img {
              width: 88px;
              height: 88px;
              border-radius: 6px;
              object-fit: cover;
            }
            .imgEmpty {
              width: 88px;
              height: 88px;
              border-radius: 6px;
              background-color: var(--unavailable);
            }
          }
          .elemInfoContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
        .minimalListElement {
          display: flex;
          flex-direction: row;
          padding: 16px 24px;
          border-bottom: 1px solid var(--border);
          cursor: pointer;
          user-select: none;
          &:hover {
            background-color: var(--surfaceLayer);
          }
        }
      }
    }
    &.topCard {
      margin-bottom: 32px;
    }
    &.leftCard {
      margin-right: 32px;
    }
    &.defaultPadding {
      padding: 24px;
    }
    &.dynamicSize {
      flex-grow: unset;
      max-height: unset;
    }
    &.fitHeight {
      height: fit-content;
    }
    &.overflowY {
      overflow-y: auto;
    }
    span.emptyText {
      text-align: center;
      display: block;
      margin: 32px 0;
      color: var(--onSurfaceLight);
      user-select: none;
    }
    .detailHeader {
      height: 72px;
      border-bottom: 1px solid var(--border);
      padding: 0 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .detailHeaderTitle {
        flex-grow: 1;
      }
      .detailHeaderActions {
        display: flex;
        flex-direction: row;
      }
    }
    .detailContent {
      overflow-y: auto;
      height: 100%;
      max-height: calc(100% - 72px);
      .insideModal {
        display: flex;
        flex-direction: column;
        padding: 24px;
        border-bottom: 1px solid var(--border);
        .insideModalActions {
          display: flex;
          flex-direction: row;
          margin-top: 16px;
        }
      }
    }
  }
  .cardContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .cardContainer,
  .card {
    &.maxHeight {
      height: calc(100vh - 184px);
    }
    &.leftView {
      margin-right: 32px;
    }
    &.sideCard {
      width: 0; /* Makes 2 cards horizontally next to each other have same width, otherwise width changes weirdly with its contents */
    }
  }
}
.variableDisplay {
  display: flex;
  flex-direction: column;
  margin-right: 64px;
  &.marginBottom {
    margin-bottom: 24px;
  }
}
.badge {
  background-color: var(--primary);
  color: var(--onPrimary);
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  // opacity: .75;
  padding: 0 8px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 4px;
  user-select: none;
  &.large {
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 16px;
  }
}

.maintenance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .activityIndicator > div {
    background-color: var(--primaryFallback);
  }
}

@media screen and (max-width: 1367px) {
  span {
    font-size: 14px;
    &.small {
      font-size: 12px;
    }
    &.big {
      font-size: 16px;
    }
    &.large {
      font-size: 19px;
    }
    &.extraLarge {
      font-size: 25px;
    }
  }
  .pageHeader {
    padding: 24px 32px 0 32px;
    .pageTitle {
      font-size: 24px;
    }
    .pageDescription {
      font-size: 16px;
    }
  }
  .pageContent {
    transform: translateY(-104px);
    padding: 0 32px;
    .card {
      max-height: calc(100vh - 152px);
      &.leftList {
        max-width: 320px;
        min-width: 320px;
        margin-right: 16px;
        .searchHeader {
          height: 64px;
        }
        & > .listContent {
          &.hasSearchHeader {
            max-height: calc(100% - 64px);
          }
        }
      }
      .detailHeader {
        height: 64px;
      }
      .detailContent {
        overflow-y: auto;
        height: 100%;
        max-height: calc(100% - 64px);
      }
    }
    .cardContainer,
    .card {
      &.maxHeight {
        height: calc(100vh - 152px);
      }
    }
  }
}

@media (hover: hover) {
  .card .listContent {
    &::-webkit-scrollbar-track {
      background-color: var(--surface);
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--border);
    }
  }
}