.appWrapper {
  overflow: hidden;
  .sidemenu {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--primary);
    width: 320px;
    height: 100vh;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.1);
    transition: transform 0.12s ease-in-out;
    .sidemenuHeader {
      height: 64px;
      box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px 21px;
      justify-content: space-between;
      .textWrapper {
        display: flex;
        flex-direction: column;
        max-width: calc(320px - 64px - 20px);
        .title {
          color: var(--onPrimary);
          font-size: 15px;
        }
        .subtitle {
          color: var(--onPrimary);
          font-size: 13px;
        }
        .title,
        .subtitle {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .entryListTop {
      flex-grow: 2;
    }
    .entryListTop,
    .entryListBottom {
      margin: 16px 0;
      .sidemenuEntry {
        height: 64px;
        position: relative;
        display: flex;
        align-items: center;
        .activeHighlight {
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 24px;
          height: 52px;
          width: calc(100% - 24px);
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          transition: background-color 0.24s ease-in-out;
          user-select: none;
          cursor: pointer;
          &:hover {
            background-color: var(--primaryLight);
          }
          i {
            margin-right: 24px;
          }
          span {
            color: var(--onPrimary);
          }
          .notificationAmount {
            position: absolute;
            top: 12px;
            left: 32px;
            background-color: var(--warning);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              display: inline-block;
              line-height: 20px;
              font-size: 14px;
            }
          }
        }
        &.active .activeHighlight {
          background-color: var(--primaryVeryLight);
        }
      }
    }
  }
  &.sidemenuCollapsed .sidemenu {
    transform: translateX(-256px);
    .sidemenuEntry {
      .activeHighlight {
        position: absolute;
        width: 64px;
        right: 0;
        padding-left: 0;
        display: flex;
        justify-content: center;
        i {
          margin-right: 0;
        }
        span {
          display: none;
        }
      }
    }
  }
  &.sidemenuHidden .sidemenu {
    width: 0px;
  }
  .appContent {
    width: calc(100vw - 320px);
    height: 100vh;
    transform: translateX(320px);
    transition: transform 0.12s ease-in-out;
    background-color: var(--background);
    &.allowVerticalScroll {
      overflow-y: auto; /* e.g. Settings card overflow on small tablet */
    }
  }
  &.sidemenuCollapsed .appContent {
    transform: translateX(64px);
    width: calc(100vw - 64px);
  }
  &.sidemenuHidden .appContent {
    width: 100vw;
    transform: translateX(0px);
  }
}

@media screen and (max-width: 1367px) {
  .appWrapper {
    .sidemenu {
      width: 240px;
      .sidemenuHeader {
        padding: 24px 21px;
        .textWrapper {
          max-width: calc(240px - 64px - 20px);
        }
        .clickable i {
          font-size: 20px !important;
        }
      }
    }
    &.sidemenuCollapsed .sidemenu {
      transform: translateX(-180px);
    }
    .appContent {
      width: calc(100vw - 240px);
      transform: translateX(240px);
    }
  }
}
