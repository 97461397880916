.settings {
  margin-bottom: -40px;
  span.title {
    display: block;
    margin-bottom: 16px;
  }
  .openingHours {
    .openingHour {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
      height: 48px;
      .dayName {
        flex-grow: 1;
        cursor: pointer;
        user-select: none;
      }
      .until {
        margin: 0 8px;
      }
      &.closed {
        i,
        span {
          color: var(--onSurfaceLight) !important;
        }
      }
    }
  }
  .defaultButton {
    margin-top: 16px;
    width: fit-content;
  }
  .specialHoursList {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    .specialHoursEntry {
      background-color: var(--surface);
      transition: background-color 0.16s ease-in-out;
      &:hover {
        background-color: var(--surfaceLayer);
      }
      &:not(:last-child) {
        border-bottom: 1px solid var(--border);
      }
      .top {
        display: flex;
        flex-direction: row;
        height: 48px;
        align-items: center;
        padding-left: 12px;
        .date {
          width: 120px;
        }
        .openValue {
          flex-grow: 1;
        }
        .deleteOption {
          padding: 12px;
          cursor: pointer;
          user-select: none;
          display: flex;
          align-items: center;
        }
        .defaultButton {
          margin: 0;
          &.flat.delete {
            background-color: transparent;
            span {
              color: var(--error);
            }
            .activityIndicator > div {
              background-color: var(--primary);
            }
          }
        }
      }
      .bottom {
        display: flex;
        flex-direction: row;
        // height: 48px;
        margin-top: -8px;
        align-items: center;
        padding-left: 12px;
        padding-bottom: 12px;
        .reason {
          margin-left: 120px;
          color: var(--onSurfaceLight);
        }
      }
    }
  }
  .addSpecialHours {
    display: flex;
    flex-direction: column;
    .topInputs {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .spacer {
        flex-grow: 1;
      }
      .dateInputValue {
        width: 110px;
      }
      .openIndicator {
        display: flex;
        flex-direction: row;
        margin: 0 8px 24px 0;
        span {
          cursor: pointer;
          user-select: none;
        }
      }
      .timeInputValue {
        width: 72px;
      }
      .defaultInput:not(:first-child) {
        margin-left: 16px;
      }
    }
  }
  .storeTagList {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    .storeTagEntry {
      display: flex;
      flex-direction: row;
      height: 48px;
      align-items: center;
      background-color: var(--surface);
      transition: background-color 0.16s ease-in-out;
      padding-left: 12px;
      &:not(:last-child) {
        border-bottom: 1px solid var(--border);
      }
      &:hover {
        background-color: var(--surfaceLayer);
      }
      .activeIndicator {
        width: 40px;
      }
      .tagName {
        flex-grow: 1;
      }
      .deleteOption {
        padding: 12px;
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
      }
      .defaultButton {
        margin: 0;
        &.flat.delete {
          background-color: transparent;
          span {
            color: var(--error);
          }
        }
        &.flat .activityIndicator > div {
          background-color: var(--primary);
        }
      }
    }
    .empty {
      color: var(--onSurfaceLight);
    }
  }
}

@media screen and (max-width: 1367px) {
  .settings {
    margin-bottom: -64px;
    .specialHoursList .specialHoursEntry {
      .top .date {
        width: 100px;
      }
      .bottom .reason {
        margin-left: 100px;
      }
    }
    .addSpecialHours .topInputs {
      .defaultInput:not(:first-child) {
        margin-left: 8px;
      }
      .openIndicator {
        .checkBox {
          margin: 0 4px 4px 0;
        }
        span {
          margin-top: 2px;
        }
      }
    }
  }
}

@media (hover: none) {
  .settings .specialHoursList .specialHoursEntry:hover {
    background-color: var(--surface);
  }
}
