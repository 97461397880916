.activityIndicator {
  margin: 40px auto;
  width: 70px;
  text-align: center;
}
.activityIndicator > div {
  width: 18px;
  height: 18px;
  background-color: var(--primary);

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.activityIndicator .bounce1 {
  animation-delay: -0.32s;
}
.activityIndicator .bounce2 {
  animation-delay: -0.16s;
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(0.8);
  }
}

.offlineIndicator {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
  .innerContainer {
    position: relative;
    left: -50%;
    background-color: var(--error);
    padding: 24px 32px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    user-select: none;
    span {
      color: var(--onError);
    }
    .top {
      display: flex;
      flex-direction: row;
      i {
        margin-right: 16px;
      }
    }
    .bottom {
      margin-top: 12px;
    }
  }
}

.notificationIndicator {
  position: absolute;
  top: 16px;
  left: 50%;
  z-index: 1;
  .innerContainer {
    position: relative;
    left: -50%;
    padding: 24px 32px;
    user-select: none;
    border: 1px solid var(--border);
    border-radius: 16px;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.1);
    width: 500px;
    max-width: 500px;
    height: 112px;
    max-height: 112px;
    cursor: pointer;
    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > i {
        margin-right: 16px;
      }
      .title {
        flex-grow: 2;
      }
      .close {
        cursor: pointer;
      }
    }
    .bottom {
      margin-top: 12px;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    &.newOrder {
      background-color: var(--surface);
      span {
        color: var(--onSurfaceStrong);
      }
    }
    &.info {
      background-color: var(--surface);
      span {
        color: var(--onSurfaceStrong);
      }
    }
    &.warning {
      background-color: var(--warning);
      span {
        color: var(--onWarning);
      }
    }
    &.error {
      background-color: var(--error);
      span {
        color: var(--onError);
      }
    }
  }
}

.dataAgeDisplay {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: var(--surface);
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid var(--border);
  padding: 12px 16px;
  .value {
    font-weight: 500;
  }
}
