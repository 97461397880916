.orderEntry {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid var(--border);
  user-select: none;
  cursor: pointer;
  background-color: var(--surface);
  transition: background-color 0.12s ease-in-out;
  position: relative;
  &:hover {
    background-color: var(--surfaceLayer);
  }
  .orderLeft {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .orderRight {
    .smallPickupCode {
      display: flex;
      justify-content: center;
      min-width: 72px;
      border: 1px solid var(--onSurfaceStrong);
      background-color: var(--background);
      padding: 4px 6px;
      border-radius: 6px;
    }
  }
  .selectedIndicator {
    position: absolute;
    width: 4px;
    height: calc(100% + 1px);
    right: 0;
    top: 0;
    background-color: var(--primary);
    opacity: 0;
    transition: opacity 0.12s ease-in-out;
  }
  &.selected {
    background-color: var(--surfaceLayer);
    .selectedIndicator {
      opacity: 1;
    }
  }
}
.orderTabs {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--border);
  height: 64px;
  .tab {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0 12px 0 16px;
    user-select: none;
    transition: background-color 0.12s ease-in-out;
    cursor: pointer;
    position: relative;
    &:hover {
      background-color: var(--surfaceLayer);
    }
    &:not(:last-child) {
      border-right: 1px solid var(--border);
    }
    i {
      margin-right: 12px;
      color: var(--onSurfaceLight) !important;
    }
    span {
      color: var(--onSurfaceLight);
    }
    &.active {
      i {
        color: var(--onSurfaceStrong) !important;
      }
      span {
        color: var(--onSurfaceStrong);
      }
    }
    .activeIndicator {
      height: 4px;
      width: calc(100% + 2px);
      position: absolute;
      top: -1px;
      left: -1px;
      background-color: var(--primary);
      &.left {
        border-top-left-radius: 6px;
      }
      &.right {
        border-top-right-radius: 6px;
      }
    }
    .amountIndicator {
      margin-left: 4px;
    }
  }
}
.order {
  display: flex;
  flex-direction: column;
  height: 100%; // Breaks everything on iOS
  .openIndicator {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 1px);
    height: 4px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: var(--warning);
  }
  .infoBox {
    padding: 32px;
    border-bottom: 1px solid var(--border);
  }
  .orderHeader {
    display: flex;
    padding: 32px;
    border-bottom: 1px solid var(--border);
    .orderHeaderLeft {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    .orderHeaderRight {
      .largePickupCode {
        display: flex;
        justify-content: center;
        min-width: 156px;
        border: 1px solid var(--onSurfaceStrong);
        background-color: var(--background);
        padding: 16px 24px;
        border-radius: 6px;
      }
    }
    .topInfos {
      display: flex;
      flex-direction: row;
      margin-top: 32px;
    }
  }
  .orderProducts {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-bottom: 1px solid var(--border);
    flex-grow: 2;
    .productList {
      margin: 16px 0;
      .orderProductEntry {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 8px;
      }
    }
    .orderProductSummary {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 8px;
    }
  }
  .orderBottom {
    display: flex;
    flex-direction: row;
    .orderContact {
      padding: 32px;
      // min-width: 100px;
      // max-width: 360px;
      border-right: 1px solid var(--border);
      .variableDisplay:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    .orderState {
      padding: 24px 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex-grow: 2;
      .orderStateProgress {
        display: flex;
        flex-direction: row;
        user-select: none;
        margin: 24px 0 32px 0;
        .orderStateIndicator {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 120px;
          .circle {
            position: relative;
            height: 40px;
            width: 40px;
            background-color: var(--onSurfaceVeryLight);
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              color: var(--surface);
              font-size: 20px;
            }
          }
          & > span {
            margin-top: 8px;
            font-size: 14px;
            color: var(--onSurfaceVeryLight);
          }
          .line {
            position: absolute;
            width: 80px;
            border: none;
            border-top: 4px dotted var(--onSurfaceVeryLight);
            top: 18px;
            left: -80px;
          }
          &.completed {
            .circle {
              background-color: var(--onSurfaceStrong);
            }
            .line {
              border-top: 4px solid var(--onSurfaceStrong);
            }
            & > span {
              color: var(--onSurfaceStrong);
            }
          }
        }
      }
      .unacceptedContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        & > span {
          margin-bottom: 24px;
        }
        .actions {
          display: flex;
          flex-direction: row;
          
          & > div {
            flex-grow: 1;
          }
        }
      }
      .activityIndicator {
        margin: 10px auto;
      }
    }
  }
}

@media screen and (max-width: 1367px) {
  .orderTabs {
    .tab {
      i {
        display: none;
      }
    }
  }
  .order {
    .orderBottom {
      flex-direction: column;
      .orderContact {
        display: flex;
        flex-direction: row;
        border-right: none;
        border-bottom: 1px solid var(--border);
        .variableDisplay {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

@media (hover: none) {
  .orderTabs .tab:hover {
    background-color: var(--surface);
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  .order {
    height: unset;
  }
}