.productSectionHeader {
  padding: 0 !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  max-width: calc(100vw - 320px - 440px - 96px - 32px);
  overflow-x: auto;
  overflow: overlay;
  .productSectionHeaderItem {
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-color: var(--surface);
    transition: background-color 0.16s ease-in-out;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-right: 1px solid var(--border);
    &:hover {
      background-color: var(--surfaceLayer);
    }
    .selectedIndicator {
      height: 4px;
      width: calc(100% + 2px);
      position: absolute;
      top: -1px;
      left: -1px;
      background-color: var(--primary);
    }
    span {
      white-space: nowrap;
    }
  }
}
.sidemenuCollapsed .productSectionHeader {
  max-width: calc(100vw - 56px - 440px - 96px - 32px);
}

.productSectionContent {
  padding: 0 32px 24px 32px;
  overflow-y: auto;
  .productSectionCategoryContainer {
    & > span {
      display: block;
      padding-top: 24px;
    }
    .productSectionListContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .offeredProductItem {
        border-radius: 6px;
        margin-top: 8px;
        margin-right: 8px;
        border: 1px solid var(--border);
        max-width: 202px;
        cursor: pointer;
        user-select: none;
        .offeredProductImage img {
          width: 200px;
          height: 120px;
          display: block;
          object-fit: cover;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
        .offeredProductInfos {
          display: flex;
          flex-direction: column;
          padding: 12px;
        }
      }
      span.emptyText {
        margin: 8px 0;
      }
    }
  }
}

.product {
  display: flex;
  flex-direction: row;
  .productInfoContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 32px 0 32px 32px;
  }
  .productImageContainer {
    padding: 32px 32px 0 0;
    img {
      display: block;
      width: 320px;
      height: 320px;
      border-radius: 6px;
      object-fit: cover;
    }
    .imgEmpty {
      width: 320px;
      height: 320px;
      border-radius: 6px;
      background-color: var(--unavailable);
    }
  }
}
.offeredProductToggleContainer {
  display: flex;
  flex-direction: row;
  padding: 32px 32px;
  align-items: center;
  border-bottom: 1px solid var(--border);
  min-height: 110px;
  span {
    flex-grow: 2;
  }
  .defaultButton {
    margin-left: 24px
  }
  .activityIndicator {
    margin: 10px auto;
  }
}

table.nutritionTable {
  width: fit-content;
  min-width: 280px;
  thead > tr > th:first-child,
  tbody > tr > td:first-child {
    text-align: left;
  }
  thead > tr > th:last-child,
  tbody > tr > td:last-child {
    text-align: right;
  }
  tbody > tr > td > span {
    white-space: nowrap;
    &.indent {
      margin-left: 12px;
      margin-right: 24px;
    }
  }
  &.edit {
    margin-bottom: 16px;
    & > thead > tr > th {
      padding-bottom: 12px;
    }
  }
  &.edit tbody > tr > td:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    input[type="number"] {
      margin-right: 8px;
      width: 96px;
    }
  }
}

@media screen and (max-width: 1367px) {
  .productSectionHeader {
    max-width: calc(100vw - 240px - 320px - 64px - 16px);
  }
  .sidemenuCollapsed .productSectionHeader {
    max-width: calc(100vw - 56px - 320px - 64px - 16px);
  }

  .product {
    .productImageContainer {
      img {
        width: 180px;
        height: 180px;
        border-radius: 6px;
        object-fit: cover;
      }
      .imgEmpty {
        width: 180px;
        height: 180px;
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .product {
    flex-direction: column-reverse;
    .productImageContainer {
      padding: 32px 32px 12px;
    }
  }
}

@media (hover: hover) {
  .productSectionHeader {
    &::-webkit-scrollbar-track {
      background-color: var(--surface);
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--border);
    }
  }
}

@media (hover: none) {
  .productSectionHeader .productSectionHeaderItem:hover {
    background-color: var(--surface);
  }
}