.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background);
  .wrapper {
    border: 1px solid var(--border);
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.1);
    padding: 32px 48px;
    border-radius: 8px;
    background-color: var(--surface);
    width: 400px;
    & > .activityIndicator {
      margin: 4px 0 0 0;
      & > div {
        background-color: var(--primaryFallback);
      }
    }
    &.form {
      min-width: 480px;
    }
    &.loadingWrapper {
      height: 160px;
      width: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      // border-radius: 80px;

      background-color: transparent;
      border: none;
      box-shadow: none;
    }
    h1 {
      margin: 0;
      font-size: 25px;
    }
    .inputWrapper {
      margin: 24px 0;
      display: flex;
      flex-direction: column;
    }
    .errorMsg {
      color: var(--error);
      margin-top: -12px;
      margin-bottom: 24px;
      display: block;
    }
    // .defaultButton {
    //   background-color: var(--primaryFallback);
    // }
  }
}