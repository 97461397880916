.defaultInput {
  display: flex;
  flex-direction: column;
  span {
    user-select: none;
  }
  input {
    padding: 12px;
    border: 1.5px solid var(--border);
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 16px;
    transition: border .16s ease-in-out, box-shadow .16s ease-in-out;
    font-size: 16px;
    // min-width: 0;
    &:focus {
      outline: none;
      border: 1.5px solid var(--primary);
      box-shadow: 0px 0px 3px 0px var(--primary);
    }
  }
}
.largeInput {
  display: flex;
  flex-direction: column;
  span {
    user-select: none;
  }
  textarea {
    padding: 12px;
    border: 1.5px solid var(--border);
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 16px;
    transition: border .16s ease-in-out, box-shadow .16s ease-in-out;
    font-size: 16px;
    // min-width: 0;
    &:focus {
      outline: none;
      border: 1.5px solid var(--primary);
      box-shadow: 0px 0px 3px 0px var(--primary);
    }
  }
}
.selectionInput {
  display: flex;
  flex-direction: column;
  span {
    user-select: none;
  }
  select {
    padding: 12px;
    border: 1.5px solid var(--border);
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 16px;
    transition: border .16s ease-in-out, box-shadow .16s ease-in-out;
    font-size: 16px;
    // min-width: 0;
    &:focus {
      outline: none;
      border: 1.5px solid var(--primary);
      box-shadow: 0px 0px 3px 0px var(--primary);
    }
  }
}
.basicInput {
  input {
    padding: 12px;
    border: 1.5px solid var(--border);
    border-radius: 4px;
    transition: border .16s ease-in-out, box-shadow .16s ease-in-out;
    font-size: 16px;
    min-width: 68px;
    &:focus {
      outline: none;
      border: 1.5px solid var(--primary);
      box-shadow: 0px 0px 3px 0px var(--primary);
    }
  }
}
.checkBox {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}