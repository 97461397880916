.defaultButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  padding: 0 24px;
  height: 44px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  transition: background-color .12s ease-in-out;
  &:hover {
    background-color: var(--primaryVeryLight);
  }
  &:not(:last-child) {
    margin-right: 16px;
  }
  span {
    color: var(--onPrimary);
    white-space: nowrap;
  }
  &.flat {
    background-color: var(--surface);
    span {
      color: var(--onSurfaceStrong)
    }
    &:hover {
      background-color: var(--surfaceLayer);
    }
  }
  &.left {
    align-self: flex-start;
  }
  &.delete {
    background-color: var(--error);
    span {
      color: var(--onError)
    }
    &:hover {
      background-color: var(--error);
    }
  }
  &.flat.delete {
    background-color: var(--onError);
    span {
      color: var(--error)
    }
    &:hover {
      background-color: var(--error);
      span {
        color: var(--onError)
      }
    }
  }
  & > .activityIndicator {
    margin: 4px 0 0 0;
    & > div {
      width: 14px;
      height: 14px;
      background-color: var(--onPrimary);
    }
  }
}

.iconButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  padding: 10px 24px;
  height: 44px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  transition: background-color .12s ease-in-out;
  &:hover {
    background-color: var(--primaryVeryLight);
  }
  i {
    margin-right: 8px;
  }
  span {
    color: var(--onPrimary);
  }
  & > .activityIndicator {
    margin: 4px 0 0 0;
    & > div {
      width: 14px;
      height: 14px;
      background-color: var(--onPrimary);
    }
  }
}

.detailActionButton {
  padding: 8px;
  margin-left: 16px;
  cursor: pointer;
  user-select: none;
  i {
    transition: color .16s ease-in-out;
  }
  &:hover i {
    color: var(--onSurfaceLight) !important;
  }
}

@media (hover: none) {
  .defaultButton:hover {
    background-color: var(--primary);
    &.flat:hover {
      background-color: var(--surface);
    }
    &.flat.delete:hover {
      background-color: var(--onError);
      span {
        color: var(--error)
      }
    }
  }
  .iconButton:hover {
    background-color: var(--primary);
  }
  .detailActionButton:hover i {
    color: var(--onSurfaceStrong) !important;
  }
}