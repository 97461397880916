.modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .modal {
        width: 800px;
        min-height: 200px;
        max-height: 80%;
        overflow-y: auto;
        background-color: var(--surface);
        border-radius: 12px;
        .modal-header {
            height: 72px;
            border-bottom: 1px solid var(--border);
            padding: 0 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            user-select: none;
            .title {
                flex-grow: 1;
            }
            .actions {
                display: flex;
                flex-direction: row;
            }
        }
        .modal-content {
            display: flex;
            flex-direction: column;
            .rejection-modal {
                display: flex;
                flex-direction: column;
                padding: 24px 24px 0 24px;
                user-select: none;
                > span {
                    margin-bottom: 24px;
                }
                .reason-list {
                    display: flex;
                    flex: 1 0 calc(50% - 16px);
                    flex-direction: row;
                    flex-wrap: wrap;
                    .reason {
                        flex-grow: 1;
                        max-width: 50%;
                        background-color: var(--skeleton);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 80px;
                        border-radius: 8px;
                        padding: 0 16px;
                        cursor: pointer;
                        span { 
                            color: var(--onSurfaceStrong);
                        }
                        &.selected {
                            background-color: var(--primary);
                            span { 
                                color: var(--onPrimary);
                            }
                        }
                        &:not(:last-child) {
                            margin-bottom: 12px;
                        }
                        &:nth-child(odd) {
                            margin-right: 16px;
                        }
                    }
                }
                .secondary-reason-container {
                    border-top: 1px solid var(--border);
                    margin: 16px 0;
                    padding-top: 24px;
                    display: flex;
                    flex-direction: column;
                    > span {
                        margin-bottom: 16px;
                    }
                    .secondary-reason {
                        display: flex;
                        flex-direction: row;
                        height: 48px;
                        align-items: center;
                        background-color: var(--skeleton);
                        padding: 0 16px;
                        border-radius: 8px;
                        cursor: pointer;
                        .name {
                            flex-grow: 1;
                        }
                        .amount-picker {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            .detailActionButton {
                                margin: 0 !important;
                            }
                        }
                        &.selected {
                            background-color: var(--primary);
                            span { 
                                color: var(--onPrimary);
                            }
                            .amount-picker .detailActionButton i {
                                color: var(--onPrimary) !important;
                            }
                        }
                        &:not(:last-child) {
                            margin-bottom: 12px;
                        }
                    }
                }
            }
            .submit {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding: 16px;
            }
            .inner-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 16px 24px 0;
                .defaultInput {
                    margin-top: 16px;
                }
            }
        }
    }
}
