body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Poppins";
  src: url("./resources/fonts/Poppins-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Poppins";
  src: url("./resources/fonts/Poppins-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url("./resources/fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;

  --background: #F0F0F0;
  --onBackground: #000000;
  --surface: #FFFFFF;
  --surfaceLayer: #F9F9FA;
  --onSurfaceStrong: #202020;
  --onSurfaceLight: #777B86;
  --onSurfaceVeryLight: #C8CCD3;
  --border: #C8CCD3;

  --success: #299315;
  --onSuccess: #ffffff;
  --error: #ff1744;
  --onError: #ffffff;
  --warning: #E37400;
  --warningLayer: #ed9a42;
  --onWarning: #ffffff;

  --disabled: #777B86;
  --onDisabled: #E6E6E6;

  --unavailable: #f0f0f0;

  --skeleton: #e6e7eb;
  --skeletonGlance: #f5f5f7;
  --skeletonDark: #d5d6db;

  --primaryFallback: #874F2B;
}

@font-face{font-family:Feather;font-style:normal;font-weight:400;src:url(resources/iconfonts/Feather.ttf) format('truetype')}
@font-face{font-family:MaterialIcons;font-style:normal;font-weight:400;src:url(resources/iconfonts/MaterialIcons.ttf) format('truetype')}