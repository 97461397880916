.customerOrderContainer {
  width: 100%;
  padding-right: 32px;
  user-select: none;
  table {
    width: 100%;
    border-collapse: collapse;
    thead > tr > th {
      text-align: left;
      padding: 8px;
    }
    tbody > tr {
      border-top: 1px solid var(--border);
    }
    tbody > tr:hover {
      background-color: var(--surfaceLayer);
      cursor: pointer;
    }
    tbody > tr > td {
      padding: 8px;
    }
  }
}
